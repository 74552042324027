import { Title } from "./index.styles";

// assets
import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";
import EmailIcon from "@mui/icons-material/Email";
import LayersIcon from "@mui/icons-material/Layers";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ReviewsIcon from "@mui/icons-material/Reviews";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import SubjectIcon from "@mui/icons-material/Subject";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import WebIcon from "@mui/icons-material/Web";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import StarBorderTwoToneIcon from "@mui/icons-material/StarBorderTwoTone";
import FormatListBulletedTwoToneIcon from "@mui/icons-material/FormatListBulletedTwoTone";
import useGQL from "hooks/useGQL";
import { QuestionMarkTwoTone } from "@mui/icons-material";

// ==============================|| MENU ITEMS ||============================== //

const MenuItems = {
  items: [
    {
      id: "dashboard",
      type: "group",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          icon: HomeIcon,
          breadcrumbs: false,
        },
        {
          id: "event",
          title: "Events",
          type: "item",
          url: "/event/list",
          icon: EventNoteTwoToneIcon,
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "user",
      title: <Title style={{ margin: 0 }}>User Management</Title>,
      type: "group",
      children: [
        {
          id: "individualUser",
          title: "Individual",
          type: "item",
          icon: PersonIcon,
          url: "/individualUser/list",
          breadcrumbs: false,
        },
        {
          id: "company",
          title: "Company",
          type: "item",
          icon: GroupIcon,
          url: "/company/list",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "reports",
      title: <Title style={{ margin: 0 }}>Reports</Title>,
      type: "group",
      children: [
        {
          id: "members",
          title: "Members",
          type: "item",
          icon: PersonIcon,
          url: "/members/list",
          breadcrumbs: false,
        },
        {
          id: "events-report",
          title: "Events",
          type: "item",
          icon: CalendarTodayIcon,
          url: "/events-report/list",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "website",
      title: <Title style={{ margin: 0 }}>Website Management</Title>,
      type: "group",
      children: [
        {
          id: "page-management",
          title: "Page Management",
          type: "item",
          icon: WebIcon,
          url: "/page-management/list",
        },
        {
          id: "speakers",
          title: "Presenters",
          type: "item",
          icon: SubjectIcon,
          url: "/speakers/list",
          breadcrumbs: false,
        },
        {
          id: "resources",
          title: "Resources",
          type: "item",
          icon: InsertDriveFileIcon,
          url: "/resources/list",
          breadcrumbs: false,
        },

        {
          id: "menu",
          title: "Menu Items",
          type: "item",
          icon: CheckTwoToneIcon,
          url: "/menu/list",
          breadcrumbs: false,
        },

        {
          id: "faq",
          title: "FAQs",
          type: "item",
          icon: QuestionMarkTwoTone,
          url: "/faq/list",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "adminsAndAccounts",
      title: <Title style={{ margin: 0 }}>Admin and accounts </Title>,
      type: "group",
      children: [
        {
          id: "admins",
          title: "Admin List",
          type: "item",
          icon: FormatListBulletedTwoToneIcon,
          url: "/admins/list",
          breadcrumbs: false,
        },
        {
          id: "membership",
          title: "Membership Management",
          type: "item",
          icon: StarBorderTwoToneIcon,
          url: "/membership/list",
          breadcrumbs: false,
        },
        {
          id: "paymentSetting",
          title: "Payment Setting",
          type: "item",
          icon: StarBorderTwoToneIcon,
          url: "/paymentSetting",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "email-template",
      type: "group",
      children: [
        {
          id: "email-template",
          title: "Email Template",
          type: "item",
          icon: EmailIcon,
          url: "/email-template/list",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "action-items",
      type: "group",
      children: [
        {
          id: "action-items",
          title: "Action Items",
          type: "item",
          icon: LayersIcon,
          url: "/action-item/list",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default MenuItems;
