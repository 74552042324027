import * as Yup from 'yup';
export const membershipValidation = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  membershipType: Yup.string().required('Membership type is required'),
  startDate: Yup.date().required('Start date is required').nullable(),
  endDate: Yup.date().required('End date is required').nullable(),
  joiningFee: Yup.number().when('membershipType', {
    is: (membershipType) => membershipType === 'INDIVIDUAL',
    then: Yup.number()
      .required('Joining fee is required')
      .min(1, 'Joining fee must be greater than or equal to 1'),
    otherwise: Yup.number().label('Joining fee').nullable(),
  }),

  joiningFeeIncGST: Yup.number().when('membershipType', {
    is: (membershipType) => membershipType === 'INDIVIDUAL',
    then: Yup.number()
      .required('Joining fee inc GST is required')
      .typeError('Joining fee inc GST must be a number'),
    otherwise: Yup.number().label('Joining fee inc GST').nullable(),
  }),
  membershipFee: Yup.number().when('membershipType', {
    is: (membershipType) => membershipType === 'INDIVIDUAL',
    then: Yup.number()
      .required('Membership fee is required')
      .min(1, 'Membership fee must be greater than or equal to 1'),
    otherwise: Yup.number().label('Membership fee').nullable(),
  }),

  membershipFeeIncGST: Yup.number().when('membershipType', {
    is: (membershipType) => membershipType === 'INDIVIDUAL',
    then: Yup.number()
      .required('Membership fee inc GST is required')
      .typeError('Membership fee including GST must be a number'),
    otherwise: Yup.number().label('Membership fee inc GST').nullable(),
  }),
  corporateFees: Yup.array().when('membershipType', {
    is: 'CORPORATE',
    then: Yup.array()
      .of(
        Yup.object().shape({
          seats: Yup.string()
            .test('seatRange', 'Invalid seat range', (value: any) => {
              // Custom validation function to check the seat range format
              // You can modify this regex to fit your specific requirements
              const seatRangeRegex = /^\d+-\d+$/;
              return seatRangeRegex.test(value);
            })
            .required('Seat range is required'),
          unitPrice: Yup.number()
            .required('Price per seat is required')
            .min(1, 'Unit price must be greater than or equal to 1'),
        })
      )
      .nullable(),
  }),
});
