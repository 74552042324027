import {
  Button,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Spinner from 'components/spinner';
import { Formik } from 'formik';
import moment from 'moment';
import { paymentMethodMap } from 'views/company/company-list/constants/types';

// import { paymentMethodMap } from '../company-list/constants/types';
const ViewInvoice = ({
  open,
  setOpen,
  refetchCompanyInformation,
  invoiceInformation,
  loading,
}) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      md: 700,
      xs: '90%',
    },
    bgcolor: '#fff',
    boxShadow: 28,
    p: 4,
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      sx={{
        '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.18)' },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {loading ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <Formik
              enableReinitialize
              initialValues={{
                invoiceNum: invoiceInformation?.invoiceNum || '',
                transactionId: invoiceInformation?.transactionId || '',
                paymentDate:
                  moment(invoiceInformation?.paymentDate).format(
                    'DD/MM/YYYY'
                  ) || '',
                paymentMethod:
                  paymentMethodMap[invoiceInformation?.paymentMethod] || '',
                paidAmount: invoiceInformation?.paidAmount || '',
                description: invoiceInformation?.description || '',
              }}
              onSubmit={(values, { setSubmitting }) => {
                /* make data sync with update userDTO */
                const input = {
                  ...values,
                };
                setSubmitting(true);
              }}
            >
              {({
                handleSubmit,
                getFieldProps,
                /* and other goodies */
              }) => (
                <form>
                  <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                      marginBottom: '1.5rem',
                    }}
                  >
                    View Payment Details
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ maxWidth: '697px' }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Invoice No"
                        label="Invoice No"
                        disabled
                        {...getFieldProps('invoiceNum')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Transaction Id"
                        label="Transaction Id"
                        disabled
                        {...getFieldProps('transactionId')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Amount"
                        label="Amount"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        {...getFieldProps('paidAmount')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Payment Date"
                        label="Payment Date"
                        disabled
                        {...getFieldProps('paymentDate')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Method"
                        label="Method"
                        disabled
                        {...getFieldProps('paymentMethod')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        placeholder="Description"
                        label="Description"
                        disabled
                        multiline
                        {...getFieldProps('description')}
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{ marginTop: '2em', display: 'flex', gap: '20px' }}
                    justifyContent="flex-end"
                  >
                    <Button
                      sx={{ color: 'white' }}
                      variant="contained"
                      onClick={() => setOpen(false)}
                    >
                      Back
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </>
    </Modal>
  );
};
export default ViewInvoice;
