// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const Industries = [
  {
    value: 'Agriculture_forestry_fishing',
    label: 'Agriculture, forestry and fishing',
  },
  {
    value: 'Mining',
    label: 'Mining',
  },
  {
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    value: 'Electricity_gas_water_waste_services',
    label: 'Electricity, gas, water and waste services',
  },
  {
    value: 'Construction',
    label: 'Construction',
  },
  {
    value: 'Wholesale_trade',
    label: 'Wholesale trade',
  },
  {
    value: 'Retail_trade',
    label: 'Retail trade',
  },
  {
    value: 'Accommodation_food_services',
    label: 'Accommodation and food services',
  },
  {
    value: 'Transport_postal_and_warehousing',
    label: 'Transport, postal and warehousing',
  },
  {
    value: 'Information_media_telecommunications',
    label: 'Information media and telecommunications',
  },
  {
    value: 'Rental_hiring_real_estate_services',
    label: 'Rental, hiring and real estate services',
  },
  {
    value: 'Professional_scientific_technical_services',
    label: 'Professional, scientific and technical services',
  },
  {
    value: 'Administrative_support_services',
    label: 'Administrative and support services',
  },
  {
    value: 'Public_administration_nd_safety_pivate',
    label: 'Public administration and safety (private)',
  },
  {
    value: 'Education_training_private',
    label: 'Education and training (private)',
  },
  {
    value: 'Health_and_social_assistance_private',
    label: 'Health care and social assistance (private)',
  },
  {
    value: 'Arts_recreation_services',
    label: 'Arts and recreation services',
  },
  {
    value: 'Other Services',
    label: 'Other Services',
  },
];

export const CountryStates = [
  {
    value: 'New_South_Wales',
    label: 'New South Wales',
  },
  {
    value: 'Queensland',
    label: 'Queensland',
  },
  {
    value: 'South_Australia',
    label: 'South Australia',
  },
  {
    value: 'Tasmania',
    label: 'Tasmania',
  },
  {
    value: 'Victoria',
    label: 'Victoria',
  },
  {
    value: 'Western_Australia',
    label: 'Western Australia',
  },
  {
    value: 'Australian_Capital_Territory',
    label: 'Australian Capital Territory',
  },
  {
    value: 'Northern_Territory',
    label: 'Northern Territory',
  },
  {
    value: 'Outside Australia',
    label: 'Outside Australia',
  },
];

export const States = [
  {
    value: '',
    label: '',
  },
  {
    value: 'New_South_Wales',
    label: 'New South Wales',
  },
  {
    value: 'Queensland',
    label: 'Queensland',
  },
  {
    value: 'South_Australia',
    label: 'South Australia',
  },
  {
    value: 'Tasmania',
    label: 'Tasmania',
  },
  {
    value: 'Victoria',
    label: 'Victoria',
  },
  {
    value: 'Western_Australia',
    label: 'Western Australia',
  },
  {
    value: 'Australian_Capital_Territory',
    label: 'Australian Capital Territory',
  },
  {
    value: 'Northern_Territory',
    label: 'Northern Territory',
  },
  {
    value: 'Outside Australia',
    label: 'Outside Australia',
  },
];

export const stateMapObject = {
  New_South_Wales: 'New South Wales',
  Queensland: 'Queensland',
  South_Australia: 'South Australia',
  Tasmania: 'Tasmania',
  Victoria: 'Victoria',
  Western_Australia: 'Western Australia',
  Australian_Capital_Territory: 'Australian Capital Territory',
  Northern_Territory: 'Northern Territory',
  'Outside Australia': 'Outside Australia',
};

export const country = [
  { value: 'Australia', label: 'Australia' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'India', label: 'India' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  {
    value: 'Central African Republic (CAR)',
    label: 'Central African Republic (CAR)',
  },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic (Czechia)', label: 'Czech Republic (Czechia)' },
  {
    value: 'Democratic Republic of the Congo',
    label: 'Democratic Republic of the Congo',
  },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  {
    value: 'Eswatini (formerly Swaziland)',
    label: 'Eswatini (formerly Swaziland)',
  },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea Bissau', label: 'Guinea Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  {
    value: "Ivory Coast (Cote d'Ivoire)",
    label: "Ivory Coast (Cote d'Ivoire)",
  },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar (formerly Burma)', label: 'Myanmar (formerly Burma)' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'North Korea', label: 'North Korea' },
  {
    value: 'North Macedonia (formerly Macedonia)',
    label: 'North Macedonia (formerly Macedonia)',
  },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestine', label: 'Palestine' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
  },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Korea', label: 'South Korea' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  {
    value: 'Timor Leste (formerly East Timor)',
    label: 'Timor Leste (formerly East Timor)',
  },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  {
    value: 'United Arab Emirates (UAE)',
    label: 'United Arab Emirates (UAE)',
  },
  { value: 'United Kingdom (UK)', label: 'United Kingdom (UK)' },
  {
    value: 'United States of America (USA)',
    label: 'United States of America (USA)',
  },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City (Holy See)', label: 'Vatican City (Holy See)' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
];

export const BannerColors = [
  {
    label: '',
    value: 'EMPTY',
  },
  {
    value: 'GRCI_BLUE',
    label: 'ACI Blue',
  },
  {
    value: 'GRCI_RED_ORANGE',
    label: 'ACI Associate Red',
  },
  {
    value: 'GRCI_GRAY',
    label: 'ACI CCRP Silver',
  },
  {
    value: 'GRCI_YELLOW',
    label: 'ACI CCRP Fellow Gold',
  },
  {
    value: 'GRCI_PURPLE',
    label: 'ACI Skilled AML Purple',
  },

  {
    value: 'GRCI_HELIOTROPE',
    label: 'ACI CAMLP Plum',
  },

  {
    value: 'GRCI_ORANGE',
    label: 'ACI CAMLP Fellow Orange',
  },
];

export const EventType = [
  {
    value: 'EVENT',
    label: 'Event',
  },
  {
    value: 'COURSE',
    label: 'Course',
  },
  {
    value: 'DISCUSSION_GROUP',
    label: 'Discussion Group',
  },
];

export const PageTypeArray = [
  {
    value: 'TYPE_1',
    label: '2 columns',
  },
  {
    value: 'TYPE_2',
    label: 'Narrow 2 columns',
  },
];

export const Modality = [
  {
    value: 'ONLINE',
    label: 'Online',
  },
  {
    value: 'FACE_TO_FACE',
    label: 'Face to Face',
  },
  {
    value: 'DISTANCE',
    label: 'Distance',
  },
  {
    value: 'HYBRID',
    label: 'Hybrid',
  },
];
export const DeliveryMode = [
  {
    value: '',
    label: '',
  },
  {
    value: 'ONLINE',
    label: 'Online',
  },
  {
    value: 'FACE_TO_FACE',
    label: 'Face to Face',
  },
  {
    value: 'DISTANCE',
    label: 'Distance',
  },
  {
    value: 'HYBRID',
    label: 'Hybrid',
  },
];

export const isComplianceArray = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Compliance and Risk 101',
    value: 'true',
  },
];

export const termArray = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Facilitator',
    value: 'Facilitator',
  },
  {
    label: 'Presenter',
    value: 'Presenter',
  },
];

export const MembershipTypes = [
  {
    value: 'CORPORATE',
    label: 'Corporate',
  },
  {
    value: 'CORPORATE_PRINCIPAL',
    label: 'Corporate Principal',
  },
];

export const MembershipMap = {
  CORPORATE: 'Corporate',
  CORPORATE_PRINCIPAL: 'Corporate Principal',
};

export const CrmCertificates = [
  {
    label: '',
    value: '',
  },
  {
    value: 'Associate',
    label: 'Associate',
  },
  {
    value: 'Certified Compliance and Risk Professional (CCRP)',
    label: 'Certified Compliance and Risk Professional (CCRP)',
  },
  {
    value:
      'Certified Compliance and Risk Professional (Fellow) - CCRP (Fellow)',
    label:
      'Certified Compliance and Risk Professional (Fellow) - CCRP (Fellow)',
  },
];

export const AmlCertificates = [
  {
    label: '',
    value: '',
  },
  {
    value: 'Skilled AML',
    label: 'Skilled AML',
  },
  {
    value: 'Certified AML Professional',
    label: 'Certified AML Professional',
  },
  {
    value: 'Certified AML Professional Fellow',
    label: 'Certified AML Professional Fellow',
  },
];

export const MembershipTypesIndividual = [
  {
    value: 'CORPORATE',
    label: 'Corporate Member',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Individual',
  },
  {
    value: 'CORPORATE_PRINCIPAL_MEMBER',
    label: 'Corporate Principal Member',
  },

  {
    value: 'INDIVIDUAL_STUDENT',
    label: 'Individual Student',
  },
  {
    value: 'INDIVIDUAL_RETIRED',
    label: 'Individual Retired',
  },
];

export const IndividualUserStatus = [
  {
    value: 'VALID',
    label: 'Valid',
  },
  {
    value: 'AWAITING_PAYMENT',
    label: 'Awaiting payment',
  },
  {
    value: 'GRACE',
    label: 'Grace',
  },
  {
    value: 'RESIGNED',
    label: 'Resigned',
  },
];
export const CorporateUserStatus = [
  {
    value: 'VALID',
    label: 'Valid',
  },

  {
    value: 'RESIGNED',
    label: 'Resigned',
  },
];

export const Category = [
  {
    value: 'ARTICLE',
    label: 'Article',
  },
  {
    value: 'VIDEO',
    label: 'Video',
  },
  {
    value: 'TOOLS',
    label: 'Tools',
  },
  {
    value: 'PODCAST',
    label: 'Podcast',
  },
  {
    value: 'JOURNALS_AND_MANAGEMENT',
    label: 'Journals & Magazines',
  },
];
