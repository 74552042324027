import { gql } from '@apollo/client';

export const UPDATE_COMPANY_INFORMATION = gql`
  mutation UpdateCorporateUserInformation(
    $input: CreateCompanyUserDTO!
    $updateCorporateUserInformationId: String
  ) {
    updateCorporateUserInformation(
      input: $input
      id: $updateCorporateUserInformationId
    ) {
      message
      user {
        _id
        firstName
        lastName
        email
        password
        phone
        company
        position
        industry
        code
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        createdAt
        membership {
          status
          membershipType
          totalSeats
          seatsTaken
          hasUnlimitedSeats
          validUptoDate
          notes {
            description
            createdAt
            status
            createdBy
          }
        }
      }
    }
  }
`;

export const UPDATE_MEMBERSHIP = gql`
  mutation UpdateCorporateUser(
    $updateCorporateUserId: String
    $input: CorporateMembershipInput!
  ) {
    updateCorporateUser(id: $updateCorporateUserId, input: $input) {
      message
      user {
        membership {
          status
          membershipType
          totalSeats
          seatsTaken
          hasUnlimitedSeats
          validUptoDate
          notes {
            description
            createdAt
            status
            createdBy
          }
        }
      }
    }
  }
`;

export const CREATE_NOTE_GQL = gql`
  mutation AddCompanyUserNote(
    $note: NotesInput!
    $addCompanyUserNoteId: String
  ) {
    addCompanyUserNote(note: $note, id: $addCompanyUserNoteId) {
      message
      user {
        membership {
          notes {
            description
            createdAt
            createdBy
            status
          }
        }
      }
    }
  }
`;

export const UPDATE_INDIVIDUAL_USER = gql`
  mutation UpdateUser($input: UserMembershipInput!, $updateUserId: String) {
    updateUser(input: $input, id: $updateUserId) {
      user {
        membership {
          membershipType
          code
          status
          validUptoDate
        }
      }
    }
  }
`;
export const ADD_INVOICE_DETAIL_OF_COMPANY = gql`
  mutation CreateCompanyPayment(
    $companyPaymentInput: createCompanyPaymentInputDto!
  ) {
    createCompanyPayment(companyPaymentInput: $companyPaymentInput) {
      amount
      paymentMethod
      invoiceNum
      transactionId
      _id
      dueDate
    }
  }
`;
