/* eslint no-underscore-dangle: 0 */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import moment from "moment";

import { headCells } from "../constants/variables";
import useAlertDialog from "hooks/common/useAlertDialog";
import useSnackbar from "../hooks/useSnackbar";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import Spinner from "components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSort } from "store/slices/table";
import {
  GetAllCorporateUsersResponse,
  InvoiceHistoryResponse,
} from "types/event";
import { StatusMappingObject } from "../constants/types";
import { useGQL } from "views/company/hooks/useGQL";
import {
  PaymentMethod,
  paymentMethodMap,
} from "views/company/company-list/constants/types";
import ViewInvoice from "../../view-invoice";
import { useLazyQuery } from "@apollo/client";
import { GET_INVOICE_PAYMENT_DETAILS } from "grapqhl/company/query";
import { IndividualUserInvoiceList } from "views/individual-user/types";
import { GET_INVOICE_INFORMATION_OF_INDIVIDUAL } from "grapqhl/individualUser/query";
import TableErrorMessage from "common/tableErrorMessage";

type Sort = { order: string; orderBy: string } | null;
type invoiceTableProps = {
  invoiceList: IndividualUserInvoiceList[];
  handleEdit: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent> | null,
    id: string
  ) => void;
  handleRefetch: () => void;
};

type Order = "asc" | "desc";
const IndividualUserInvoiceTable = ({
  invoiceList,
  handleEdit,
  handleRefetch,
}: invoiceTableProps) => {
  const dispatch = useDispatch();
  const {
    sort: { order, orderBy },
  } = useSelector((state: RootState) => state.table);

  const [invoiceId, setInvoiceId] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [invoiceInformation, setInvoiceInformation] = useState({});

  const [
    getInvoiceInformation,
    { refetch: refetchINvoiceInformation, loading },
  ] = useLazyQuery(GET_INVOICE_INFORMATION_OF_INDIVIDUAL);

  useEffect(() => {
    handleRefetch();
  }, [order, orderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const setOrder = isAsc ? "desc" : "asc";
    dispatch(setSort({ order: setOrder, orderBy: property }));
  };

  const handleAction = async (id: string) => {
    setOpen(true);

    setInvoiceId(id);
    const { data, loading } = await getInvoiceInformation({
      variables: { invoiceId: id },
    });
    if (data?.getInvoicePayment) {
      setInvoiceInformation(data?.getInvoicePayment);
      setOpen(true);
    }
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* table body */}
          <TableBody>
            {invoiceList.length === 0 ? (
              <TableErrorMessage colSpan={12} />
            ) : (
              invoiceList.map((invoice) => (
                <TableRow key={invoice._id}>
                  <TableCell align="left">{invoice?.invoiceNum}</TableCell>
                  <TableCell align="center">
                    {invoice?.transactionId || "-"}
                  </TableCell>
                  <TableCell align="left">{invoice?.paidAmount}</TableCell>
                  <TableCell align="left">
                    {moment(invoice?.paymentDate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {paymentMethodMap[invoice?.paymentMethod]}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "400px", // Increase the maximum width to provide more space
                      wordWrap: "break-word",
                    }}
                    align="left"
                  >
                    {invoice?.description}
                  </TableCell>
                  <TableCell align="center" sx={{ pr: 3 }}>
                    <Button onClick={(e) => handleAction(invoice._id)}>
                      View Payment Record
                    </Button>
                  </TableCell>

                  <ViewInvoice
                    open={open}
                    setOpen={setOpen}
                    invoiceInformation={invoiceInformation}
                    refetchCompanyInformation=""
                    loading={loading}
                  />
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IndividualUserInvoiceTable;
