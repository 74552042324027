import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
  query GetUser($getUserId: String!) {
    getUser(id: $getUserId) {
      message
      user {
        _id
        firstName
        lastName
        email
        phone
        position
        company
        industry
        timeInProfession
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        certification {
          crm
          aml
        }
        status
        membership {
          membershipType
          code
          status
          validUptoDate
          memberSince
        }
        isGuestUser
        hasPaidJoiningFee
      }
    }
  }
`;

export const GET_INDIVIDUAL_USER_LIST = gql`
  query GetAllUsers($input: GetUsersListDTO!) {
    getAllUsers(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      users {
        _id
        firstName
        lastName
        email
        phone
        position
        company
        status
        industry
        timeInProfession
        isGuestUser
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        certification {
          crm
          aml
        }
        status
        membership {
          membershipType
          code
          status
          validUptoDate
          memberSince
        }
      }
    }
  }
`;
export const GET_INDIVIDUAL_USER_CPD_LIST = gql`
  query GetAllCpdPoints($input: GetCPdListInput!) {
    getAllCpdPoints(input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      cpdList {
        _id
        eventId
        eventName
        description
        units
        eventDate
        points
        status
        rejectionDescription
      }
    }
  }
`;

export const GET_CPD_INFORMATION = gql`
  query ($cpdId: String!) {
    getCpdPointDetail(cpdId: $cpdId) {
      _id
      createdBy
      rejectionDescription
      eventDate
      eventId
      eventName
      description
      points
      units
    }
  }
`;

export const GET_USER_NOTES_LIST = gql`
  query GetUserNotes($getUserNotesListInput: GetUserNoteListInputDto!) {
    getUserNotes(getUserNotesListInput: $getUserNotesListInput) {
      message
      pagination {
        total
        hasNextPage
      }
      userNotes {
        status
        createdBy
        userId
        createdAt
        updatedAt
        description
      }
    }
  }
`;

export const GET_EVENT_HISTORY = gql`
  query GetUserEventBookingHistory(
    $eventHistoryInput: UserEventBookingHistoryInputDto!
  ) {
    getUserEventBookingHistory(eventHistoryInput: $eventHistoryInput) {
      message
      pagination {
        total
        hasNextPage
      }
      eventBookingHistory {
        bookingDate
        event {
          _id
          title
          eventType
          sessions {
            startDate
            endDate
            startTime
            endTime
            modality
            link
            location
          }
        }
        status
      }
    }
  }
`;
export const GET_END_DATE_OF_INDIVIDUAL_USER = gql`
  query SearchMembershipTypeExpiry {
    searchMembershipTypeExpiry {
      endDate
    }
  }
`;

export const GET_INVOICE_HISTORY_OF_INDIVIDUAL_USER = gql`
  query GetAllUserInvoicePayments(
    $getInvoicePaymentInput: GetPaymentListInputDto!
  ) {
    getAllUserInvoicePayments(getInvoicePaymentInput: $getInvoicePaymentInput) {
      message
      pagination {
        total
        hasNextPage
      }
      paymentList {
        _id
        invoiceNum
        transactionId
        paymentDate
        paymentMethod
        paidAmount
        description
        dueDate
      }
    }
  }
`;

export const GET_INVOICE_INFORMATION_OF_INDIVIDUAL = gql`
  query GetInvoicePayment($invoiceId: String!) {
    getInvoicePayment(invoiceId: $invoiceId) {
      _id
      invoiceNum
      transactionId
      paymentDate
      paymentMethod
      paidAmount
      description
      dueDate
    }
  }
`;
