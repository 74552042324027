import ReactDOM from "react-dom";

// third party
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import App from "App";
import { BASE_PATH } from "config";
import { store } from "store";
import * as serviceWorker from "serviceWorker";
import reportWebVitals from "reportWebVitals";
import { ConfigProvider } from "contexts/ConfigContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  Observable,
  FetchResult,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

// style + assets
import "assets/scss/style.scss";
import "react-nestable/dist/styles/index.css";
import { GraphQLError } from "graphql";
import { REFRESH_TOKEN } from "grapqhl";
import { useEffect } from "react";
/* GRAPHQL CLIENT INIT  */
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("accessToken");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Handle apollo error for refresh token rotation
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        let retries = 0;
        switch (err.extensions.code) {
          case "UNAUTHENTICATED":
            // ignore 401 error for a refresh request
            if (operation.operationName === "RefreshToken") {
              localStorage.clear();
              document.location.href = "/";
              return;
            }

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await refreshToken();
                    if (!accessToken) {
                      throw new GraphQLError("Empty AccessToken");
                    }
                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );

            return observable;
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

// Request a refresh token to then stores and returns the accessToken.
const refreshToken = async () => {
  try {
    let refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      return "";
    }
    const refreshResolverResponse: any = await client.mutate<{
      refreshToken: string;
    }>({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken },
    });
    const accessToken = refreshResolverResponse?.data?.refresh?.accessToken;
    refreshToken = refreshResolverResponse?.data?.refresh?.refreshToken;

    localStorage.setItem("accessToken", accessToken || "");
    localStorage.setItem("refreshToken", refreshToken || "");

    return await accessToken;
  } catch (err) {
    // localStorage.clear();
    throw err;
  }
};

const IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds

let timeoutId;

const resetTimeout = () => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(logout, IDLE_TIMEOUT);
};

const logout = () => {
  // Perform logout actions here
  localStorage.clear();
  document.location.href = "/";
};

const handleUserActivity = () => {
  resetTimeout();
};

const AppWithIdleTimeout = () => {
  useEffect(() => {
    // Set up initial timeout
    resetTimeout();

    // Add event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, []);

  return <App />;
};

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});
// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider>
      <BrowserRouter basename={BASE_PATH}>
        <ApolloProvider client={client}>
          <AppWithIdleTimeout />
        </ApolloProvider>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

reportWebVitals();
