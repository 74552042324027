/* eslint no-nested-ternary: 0 */
/* eslint no-underscore-dangle: 0 */
// material-ui
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Modal,
  OutlinedInput,
} from "@mui/material";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Spinner from "components/spinner";
import useSnackbar from "hooks/common/useSnackbar";
// import { membershipValidationSchema } from './membershipValidationSchema';
import { CorporateMembership } from "types/event";
import {
  CorporateUserStatus,
  IndividualUserStatus,
  MembershipTypes,
  MembershipTypesIndividual,
} from "store/constant";
import lang from "constants/language";
import { useEffect, useState } from "react";
import moment from "moment";
import PopModal from "common/pop-model";
import { useGQL } from "./hooks/useGQL";
import { individualMembershipInputDto } from "./types";
import { values } from "lodash";
import InputDatePopModal from "./dateFieldPopup";

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //

const IndividualMembershipInformation = ({
  userInformation,
  refetchIndividualInformation,
  endDate,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { handleOpenSnackbar } = useSnackbar();
  const [disableCode, setDisableCode] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("UserId") as string;
  const { UPDATE_INDIVIDUAL_MEMBERSHIP, GET_END_DATE } = useGQL();
  const [handleUpdate, { data: update, loading }] =
    UPDATE_INDIVIDUAL_MEMBERSHIP();
  const [open, setOpen] = useState<boolean>(false);

  const validUpToDate = userInformation?.membership?.validUptoDate
    ? userInformation?.membership?.validUptoDate
    : endDate;

  const [codeData, setCodeData] = useState("");

  const membershipMappingObj = {
    CORPORATE: "Corporate Member",
    INDIVIDUAL: "Individual",
    CORPORATE_PRINCIPAL_MEMBER: "Corporate Principal Member",
    INDIVIDUAL_STUDENT: "Individual Student",
    INDIVIDUAL_RETIRED: "Individual Retired",
  };

  useEffect(() => {
    if (update?.updateUser) {
      handleOpenSnackbar({
        message: userId
          ? lang.COMPANY.MEMBERSHIP_UPDATE
          : lang.COMPANY.MEMBERSHIP_CREATE,
        alertType: "success",
      });
    }
  }, [update]);
  if (loading) {
    <Spinner />;
  }
  useEffect(() => {
    if (userInformation) {
      setCodeData(userInformation?.membership?.code);
    }
  }, [userInformation]);

  useEffect(() => {
    if (
      userInformation?.membership?.membershipType === "INDIVIDUAL" ||
      userInformation?.membership?.membershipType === "INDIVIDUAL_STUDENT" ||
      userInformation?.membership?.membershipType === "INDIVIDUAL_RETIRED"
    ) {
      setDisableCode(true);
    } else {
      setDisableCode(false);
    }
  }, [userInformation?.membership]);

  const handleDisable = (value, setFieldValue) => {
    if (
      value === "INDIVIDUAL" ||
      value === "INDIVIDUAL_STUDENT" ||
      value === "INDIVIDUAL_RETIRED"
    ) {
      setDisableCode(true);
      setCodeData("");
      setFieldValue("status", "AWAITING_PAYMENT");
    } else {
      setFieldValue("status", "VALID");
      setDisableCode(false);
    }
  };
  const handleMembershipTypeUpdate = async (
    values: Partial<individualMembershipInputDto>,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (values?.status === "AWAITING_PAYMENT") {
      values.validUptoDate = null;
    }
    if (values?.status === "GRACE") {
      values.validUptoDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 3,
        new Date().getDate()
      );
    }

    if (values?.status === "VALID") {
      values.validUptoDate = endDate;
    }
    try {
      await handleUpdate({
        variables: {
          updateUserId: userId,
          input: {
            ...values,
            companyMemberExist: userInformation?.membership?.code
              ? true
              : false,

            status: values?.status,
          },
        },
      });
      setSubmitting(false);
      refetchIndividualInformation();
    } catch (err: any) {
      handleOpenSnackbar({ message: err.message, alertType: "error" });
      setSubmitting(false);
    }
  };
  const checkMembershipType = ["CORPORATE", "CORPORATE_PRINCIPAL_MEMBER"];

  return (
    <>
      <FormWrapper>
        <Formik
          enableReinitialize={true}
          initialValues={{
            membershipType: userInformation?.isGuestUser
              ? ""
              : userInformation?.membership?.membershipType || "",
            status: userInformation?.isGuestUser
              ? ""
              : userInformation?.membership?.status || "",
            code: userInformation?.isGuestUser
              ? ""
              : userInformation?.membership?.code || "",
          }}
          validationSchema={Yup.object().shape({
            membershipType: Yup.string()
              .nullable()
              .required()
              .label("Membership Type"),
            code: Yup.string().when("membershipType", {
              is: (membershipType) =>
                checkMembershipType.includes(membershipType),
              then: Yup.string().required().label("Company Code"),
              otherwise: Yup.string().label("Company Code"),
            }),
            status: Yup.string().required().label("Status"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            /* make data sync with update userDTO */
            const input = {
              ...values,
              code: codeData,
              validUptoDate: checkMembershipType.includes(
                values?.membershipType
              )
                ? null
                : validUpToDate,
            };

            setSubmitting(true);
            handleMembershipTypeUpdate(input, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            handleChange,
            getFieldProps,
            setFieldValue,

            /* and other goodies */
          }) => (
            <form onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id="membershipType"
                    label="Membership Type"
                    select
                    fullWidth
                    sx={{ width: "100%" }}
                    {...getFieldProps("membershipType")}
                  >
                    {MembershipTypesIndividual.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={(e) =>
                          handleDisable(option.value, setFieldValue)
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {touched.membershipType && errors.membershipType && (
                    <FormHelperText error id="membershipType-error">
                      {errors.membershipType}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="corporate code"
                    label="Corporate Code"
                    fullWidth
                    value={codeData}
                    disabled={disableCode}
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setCodeData(e.target.value);
                      setFieldValue("code", e.target.value);
                    }}
                  ></TextField>

                  {touched.code && errors.code && (
                    <FormHelperText error id="code-error">
                      {errors.code}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    mb={1}
                  >
                    <Typography
                      sx={{ marginBottom: "0.5rem" }}
                      variant="h5"
                      component="h1"
                    >
                      Status
                    </Typography>
                    <Grid item xs={3}>
                      <TextField
                        id="Status"
                        label="Status"
                        select
                        fullWidth
                        sx={{ width: "100%" }}
                        {...getFieldProps("status")}
                      >
                        {(disableCode === false
                          ? CorporateUserStatus
                          : IndividualUserStatus
                        ).map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      {touched.status && errors.status && (
                        <FormHelperText error id="status-error">
                          {errors.status}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Stack>
                </Grid>

                <Grid marginTop={"0.5rem"} item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    mb={1}
                  >
                    <Typography
                      sx={{ marginBottom: "0.5rem" }}
                      variant="h5"
                      component="h1"
                    >
                      Paid till
                    </Typography>
                    <Stack direction="row">
                      <Typography
                        sx={{
                          marginBottom: "0.5rem",
                        }}
                        variant="h5"
                        component="h1"
                      >
                        {" "}
                        {userInformation?.membership?.validUptoDate
                          ? moment(
                              userInformation?.membership?.validUptoDate
                            ).format("DD/MM/YY")
                          : "-"}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: "0.1.5rem",
                          marginX: "0.4rem",
                          color: "#004C8F",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        variant="h5"
                        component="h1"
                        onClick={(e) => {
                          setOpen(true);
                        }}
                      >
                        Edit
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    mb={1}
                  >
                    <Typography
                      sx={{ marginBottom: "0.5rem" }}
                      variant="h5"
                      component="h1"
                    >
                      Current membership type
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "0.5rem",
                      }}
                      variant="h5"
                      component="h1"
                    >
                      {userInformation.isGuestUser
                        ? "-"
                        : membershipMappingObj[
                            userInformation?.membership?.membershipType
                          ]}
                    </Typography>
                  </Stack>
                  {userInformation?.membership?.membershipType &&
                    userInformation?.membership?.membershipType.includes(
                      "CORPORATE"
                    ) && (
                      <>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          mb={1}
                        >
                          <Typography></Typography>
                          <Typography
                            sx={{
                              marginBottom: "0.5rem",
                            }}
                            variant="h5"
                            component="h1"
                          >
                            {userInformation?.company}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                          mb={1}
                        >
                          <Typography></Typography>
                          <Typography
                            sx={{
                              marginBottom: "0.5rem",
                              color: "#004C8F",
                              textDecoration: "underline",
                            }}
                            variant="h5"
                            component="h1"
                          >
                            {userInformation?.membership?.code}
                          </Typography>
                        </Stack>
                      </>
                    )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{ color: "white" }}
                  >
                    {isSubmitting ? "Please wait..." : "Save Changes"}
                  </Button>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      </FormWrapper>
      <InputDatePopModal
        open={open}
        setOpen={setOpen}
        validUpToDate={userInformation?.membership?.validUptoDate}
        userId={userId}
        refetchIndividualInformation={refetchIndividualInformation}
        // membershipInformation={membershipInformation}
        // refetchCompanyInformation={refetchCompanyInformation}
      />
    </>
  );
};

const FormWrapper = styled.div`
  padding: 1rem;
`;

export default IndividualMembershipInformation;
