import { gql } from "@apollo/client";

export const GET_MEMBERS_REPORT = gql`
  query GenerateMemberReports(
    $getMemberListReportInput: GetMembershipReportListDto!
  ) {
    generateMemberReports(getMemberListReportInput: $getMemberListReportInput) {
      message
      pagination {
        total
        hasNextPage
      }
      memberReportList {
        _id
        firstName
        memberNumber
        lastName
        position
        company
        industry
        timeInProfession
        membership {
          membershipType
          code
          status
          validUptoDate
          paymentDate
        }
        phone
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        email
        certification {
          crm
          aml
        }
        isGuestUser
      }
    }
  }
`;

export const GET_MEMBERS_REPORT_FOR_EXPORT = gql`
  query GenerateMemberReportsForExport(
    $getMemberListReportInputForExport: GetMembershipReportListExportDto!
  ) {
    generateMemberReportsForExport(
      getMemberListReportInputForExport: $getMemberListReportInputForExport
    ) {
      memberReportList {
        _id
        firstName
        memberNumber
        lastName
        position
        company
        industry
        timeInProfession
        membership {
          membershipType
          code
          status
          validUptoDate
          paymentDate
        }
        phone
        address {
          flatUnit
          streetNumber
          streetName
          postcode
          state
          suburb
          country
        }
        email
        certification {
          crm
          aml
        }
        isGuestUser
      }
    }
  }
`;
