import { lazy } from "react";
// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import EventManagement from "views/event";
import EventList from "views/event/event-list";
import CompanyList from "views/company/company-list";
import IndividualUserManagement from "views/individual-user/individualUserManagement";
import IndividualUserList from "views/individual-user/individual-user-list";
import CreateResourceInformation from "views/resources/create-resourceInformation";
import ViewResourceInformation from "views/resources/view-resourceInformation";
import ResourceList from "views/resources/resource-list";
import EventReport from "views/event-reports/event-report-list";
import EventReports from "views/event-reports/event-report-list";
import MembersReport from "views/members-report/members-report-list";
import MembersReports from "views/members-report/members-report-list";
import MembershipList from "views/membershipManagement/membership/membership-list";
import AddMembership from "views/membershipManagement/membership/Add-membership";
import ViewMembership from "views/membershipManagement/membership/viewMembership";
import CustomerList from "views/userManagement/admins";
import AdminsList from "views/membershipManagement/admins";
import AdminAddForm from "views/membershipManagement/admins/forms/add-admin";
import AdminManagementIndex from "views/membershipManagement/admins/adminManagement";
import AdminEditForm from "views/event/event-list/forms/edit-admin";
import NotificationList from "views/notifications";
import PaymentSetting from "views/profile/PaymentSettings";
import ActionItemList from "views/actionItemList";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/default"))
);

//event management routing
// const EventManagement = Loadable(lazy(() => import('views/EventManagement')));
// const EventManagementDefault = Loadable(lazy(() => import('views/event-management/default')));

// menu routing
const MenuList = Loadable(lazy(() => import("views/menu/menusList")));
const AddMenu = Loadable(lazy(() => import("views/menu/addMenu")));
const EditMenu = Loadable(lazy(() => import("views/menu/editMenu")));

// page management routing
const PageList = Loadable(lazy(() => import("views/pageManagement")));
const AdvancedPageList = Loadable(
  lazy(() => import("views/pageManagement/advancedPage"))
);
const AddPage = Loadable(
  lazy(() => import("views/pageManagement/forms/addPage"))
);
const EditPage = Loadable(
  lazy(() => import("views/pageManagement/advancedPageEdit"))
);

// menu routing
const TaxonomyList = Loadable(
  lazy(() => import("views/taxonomy/taxonomyList"))
);
const ViewTaxonomy = Loadable(lazy(() => import("views/taxonomy/taxonsList")));
const AddTaxon = Loadable(lazy(() => import("views/taxonomy/addTaxonForm")));
const EditTaxon = Loadable(lazy(() => import("views/taxonomy/editTaxonForm")));

// User managemenet routing
const AdminList = Loadable(lazy(() => import("views/userManagement/admins")));
const AdminAdd = Loadable(
  lazy(() => import("views/userManagement/admins/forms/add-admin"))
);
const AdminProfile = Loadable(lazy(() => import("views/profile")));
const AdminAccountProfile = Loadable(
  lazy(() => import("views/profile/user-profile"))
);
const AdminChangePassword = Loadable(
  lazy(() => import("views/profile/change-password"))
);
const UserList = Loadable(lazy(() => import("views/userManagement/users")));
const UserProfile = Loadable(
  lazy(() => import("views/userManagement/users/profile"))
);
const AppUserList = Loadable(
  lazy(() => import("views/userManagement/appUsers"))
);
const AppUserProfile = Loadable(
  lazy(() => import("views/userManagement/appUsers/profile"))
);
const AddAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/AddAppUser"))
);
const EditAppUser = Loadable(
  lazy(() => import("views/userManagement/appUsers/forms/EditAppUser"))
);

/* faq */
const FAQList = Loadable(lazy(() => import("views/FAQ")));
const AddFAQ = Loadable(lazy(() => import("views/FAQ/forms/addForm")));
const AddFAQV1 = Loadable(lazy(() => import("views/FAQ/forms/addForm/v1")));
const EditFAQ = Loadable(lazy(() => import("views/FAQ/forms/editForm")));
const EditFAQV1 = Loadable(
  lazy(() => import("views/FAQ/forms/editForm/v1/EditFAQ"))
);
const ManageFAQ = Loadable(lazy(() => import("views/FAQ/tables")));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import("views/testimonials")));
const AddTestimonials = Loadable(
  lazy(() => import("views/testimonials/forms/AddTestimonials"))
);
const EditTestimonials = Loadable(
  lazy(() => import("views/testimonials/forms/EditTestimonials"))
);

/* email template */
const EmailTemplateList = Loadable(lazy(() => import("views/emailTemplate")));
const AddEmailTemplate = Loadable(
  lazy(() => import("views/emailTemplate/forms/addTemplate"))
);
const EditEmailTemplate = Loadable(
  lazy(() => import("views/emailTemplate/forms/editTemplate"))
);

//Speakers
const SpeakersList = Loadable(lazy(() => import("views/speakers")));
const AddSpeaker = Loadable(
  lazy(() => import("views/speakers/forms/AddSpeaker"))
);
const EditSpeaker = Loadable(
  lazy(() => import("views/speakers/forms/EditSpeaker"))
);

const CompanyUserManagement = Loadable(
  lazy(() => import("views/company/companyManagement"))
);

// Mock component
const MockComponent = Loadable(lazy(() => import("views/mock")));

// Error routing
const ErrorPage = Loadable(lazy(() => import("views/pages/maintenance/error")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "default",
      element: <DashboardDefault />,
    },
    {
      path: "menu/list",
      element: <MenuList />,
    },
    {
      path: "menu/add",
      element: <AddMenu />,
    },
    {
      path: "menu/edit/:id",
      element: <EditMenu />,
    },

    {
      path: "taxonomy/list",
      element: <TaxonomyList />,
    },
    {
      path: "taxonomy/view/:taxonomyId",
      element: <ViewTaxonomy />,
    },
    {
      path: "taxon/add/:taxonomyId",
      element: <AddTaxon />,
    },
    {
      path: "taxon/edit/:taxonomyId/:id",
      element: <EditTaxon />,
    },

    {
      path: "page-management/add",
      element: <AddPage />,
    },
    {
      path: "page-management/edit/:id",
      element: <EditPage />,
    },
    {
      path: "page-management/list",
      element: <PageList />,
    },
    {
      path: "page-management/add/advance",
      element: <AdvancedPageList />,
    },
    {
      path: "user/list",
      element: <UserList />,
    },
    {
      path: "user/profile/:id",
      element: <UserProfile />,
    },
    {
      path: "user/list",
      element: <AppUserList />,
    },
    {
      path: "user/add",
      element: <AddAppUser />,
    },
    {
      path: "user/edit/:id",
      element: <EditAppUser />,
    },
    {
      path: "user/profile/:id",
      element: <AppUserProfile />,
    },
    //company users
    {
      path: "company/list",
      element: <CompanyList />,
    },
    {
      path: "company/add",
      element: <CompanyUserManagement />,
    },
    {
      path: "company/edit/:id",
      element: <EditAppUser />,
    },
    {
      path: "admins/list",
      element: <AdminsList />,
    },

    {
      path: "admins/add",
      element: <AdminManagementIndex />,
    },
    {
      path: "admin/profile",
      element: <AdminProfile />,
    },
    {
      path: "admin/account-profile",
      element: <AdminAccountProfile />,
    },
    {
      path: "admin/change-password",
      element: <AdminChangePassword />,
    },
    {
      path: "faq/list",
      element: <FAQList />,
    },
    {
      path: "faq/add",
      element: <AddFAQV1 />,
    },
    {
      path: "faq/edit/:id",
      element: <EditFAQV1 />,
    },
    {
      path: "faq/manage-faq",
      element: <ManageFAQ />,
    },
    {
      path: "email-template/list",
      element: <EmailTemplateList />,
    },
    {
      path: "email-template/add",
      element: <AddEmailTemplate />,
    },
    {
      path: "email-template/edit/:id",
      element: <EditEmailTemplate />,
    },
    {
      path: "speakers/list",
      element: <SpeakersList />,
    },

    {
      path: "speakers/add",
      element: <AddSpeaker />,
    },
    {
      path: "speakers/edit/:id",
      element: <EditSpeaker />,
    },

    {
      path: "mock",
      element: <MockComponent />,
    },
    {
      path: "events",
      element: <EventManagement />,
    },
    {
      path: "event/list",
      element: <EventList />,
    },

    {
      path: "individualUser/add",
      element: <IndividualUserManagement />,
    },
    {
      path: "individualUser/list",
      element: <IndividualUserList />,
    },
    {
      path: "resources/add",
      element: <CreateResourceInformation />,
    },
    {
      path: "resources/view",
      element: <ViewResourceInformation />,
    },
    {
      path: "resources/list",
      element: <ResourceList />,
    },
    {
      path: "events-report/list",
      element: <EventReports />,
    },
    {
      path: "members/list",
      element: <MembersReports />,
    },
    {
      path: "membership/list",
      element: <MembershipList />,
    },
    {
      path: "membership/add",
      element: <AddMembership />,
    },
    {
      path: "membership/view",
      element: <ViewMembership />,
    },
    {
      path: "notifications/list",
      element: <NotificationList />,
    },
    {
      path: "paymentSetting",
      element: <PaymentSetting />,
    },
    {
      path: "action-item/list",
      element: <ActionItemList />,
    },
  ],
};

export default MainRoutes;
