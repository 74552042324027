import { useMutation, useQuery } from '@apollo/client';
import { Input } from '@mui/material';
import {
  UPDATE_MEMBERSHIP,
  UPDATE_COMPANY_INFORMATION,
  CREATE_NOTE_GQL,
  UPDATE_INDIVIDUAL_USER,
  ADD_INVOICE_DETAIL_OF_COMPANY,
} from 'grapqhl/company/mutation';
import {
  GET_ALL_COMPANY_USERS,
  GET_ALL_INVOICE_HISTORY,
  GET_ALL_USERS,
  GET_COMPANY_INFORMATION_DETAILS,
  GET_INVOICE_PAYMENT_DETAILS,
  GET_USERS,
} from 'grapqhl/company/query';
import { GET_EVENT_INFORMATION } from 'grapqhl/event/query';
import { CompanyInformation, CompanyResponse } from 'types/company';
import {
  CorporateMembership,
  CorporateMembershipResponse,
  getEventResponse,
  GetAllCorporateUsersDto,
  UpdateMembershipInformation,
  NotesInputDto,
  CreateNoteResponse,
  UsersDtoForCurrentSeats,
  UpdateIndividualUser,
  UpdateIndividualInformation,
  InvoiceHistoryListInputDto,
  GetInvoicePaymentInputDto,
  AddInvoiceInputDto,
  AddInvoiceResponse,
} from 'types/event';

export const useGQL = () => {
  const UPDATE_COMPANY_INFORMATIONS = () =>
    useMutation<
      CompanyResponse,
      {
        updateCorporateUserInformationId: string;
        input: Partial<CompanyInformation>;
      }
    >(UPDATE_COMPANY_INFORMATION);

  const GET_EVENT_INFORMATION_DETAILS = (docId: string) =>
    useQuery<getEventResponse, { docId: string }>(GET_EVENT_INFORMATION, {
      variables: { docId },
    });

  const GET_CORPORATE_MEMBERSHIP_INFORMATION = (getCorporateUserId: string) =>
    useQuery(GET_COMPANY_INFORMATION_DETAILS, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: { getCorporateUserId },
    });

  const UPDATE_CORPORATE_MEMBERSHIP = () =>
    useMutation<
      UpdateMembershipInformation,
      { updateCorporateUserId: string; input: Partial<CorporateMembership> }
    >(UPDATE_MEMBERSHIP);

  const GET_ALL_CORPORATE_USERS = (input: GetAllCorporateUsersDto = {}) =>
    useQuery(GET_ALL_COMPANY_USERS, { variables: { input } });

  const CREATE_NOTE = () =>
    useMutation<
      CreateNoteResponse,
      { addCompanyUserNoteId: string; note: Partial<NotesInputDto> }
    >(CREATE_NOTE_GQL);

  const LIST_USERS_FOR_CURRENT_SEATINGS = (
    code: string,
    input: UsersDtoForCurrentSeats
  ) => useQuery(GET_USERS, { variables: { input, code } });

  const UPDATE_INDIVIDUAL_USER_DETAILS = () =>
    useMutation<
      UpdateIndividualInformation,
      { updateUserId: string; input: Partial<UpdateIndividualUser> }
    >(UPDATE_INDIVIDUAL_USER);

  const ALL_USERS_FOR_CURRENT_SEATINGS = (code: string) =>
    useQuery(GET_ALL_USERS, { variables: { code } });

  const GET_INVOICE_HISTORY = (
    getAllCompanyPaymentsInput: InvoiceHistoryListInputDto = {}
  ) =>
    useQuery(GET_ALL_INVOICE_HISTORY, {
      variables: { getAllCompanyPaymentsInput },
    });

  const GET_INVOICE_DETAIL = (paymentId: string) =>
    useQuery(GET_INVOICE_PAYMENT_DETAILS, {
      variables: { paymentId },
    });

  const ADD_INVOICE_DETAIL = () =>
    useMutation<
      AddInvoiceResponse,
      { companyPaymentInput: Partial<AddInvoiceInputDto> }
    >(ADD_INVOICE_DETAIL_OF_COMPANY);

  return {
    UPDATE_COMPANY_INFORMATIONS,
    UPDATE_CORPORATE_MEMBERSHIP,
    GET_EVENT_INFORMATION_DETAILS,
    GET_ALL_CORPORATE_USERS,
    GET_CORPORATE_MEMBERSHIP_INFORMATION,
    CREATE_NOTE,
    LIST_USERS_FOR_CURRENT_SEATINGS,
    UPDATE_INDIVIDUAL_USER_DETAILS,
    ALL_USERS_FOR_CURRENT_SEATINGS,
    GET_INVOICE_HISTORY,
    GET_INVOICE_DETAIL,
    ADD_INVOICE_DETAIL,
  };
};
