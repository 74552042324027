import { HeadCell } from "./types";

// table header options
export const headCells: HeadCell[] = [
  {
    id: "fullName",
    numeric: false,
    label: "Name",
    align: "left",
    sort: false,
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    align: "left",
    sort: true,
  },
  {
    id: "membershipType",
    numeric: false,
    label: "Membership Type",
    align: "left",
    sort: true,
  },
  {
    id: "isGuestUser",
    numeric: false,
    label: "Guest User",
    align: "left",
    sort: true,
  },

  {
    id: "company",
    numeric: true,
    label: "Company",
    align: "left",
    sort: false,
  },
  {
    id: "membershipStatus",
    numeric: false,
    label: "Membership Status",
    align: "left",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    label: "User Status",
    align: "left",
    sort: true,
  },
  {
    id: "action",
    numeric: false,
    label: "Action ",
    align: "center",
    sort: false,
  },
];

export const initialValues = {
  name: "",
  email: "",
  membershipType: "",
  company: "",
  status: "",
};
