const lang = {
  IMAGE_MAX_SIZE_VALIDATION: 'Image size must be less than 1mb.',
  INVALID_FILE_FORMAT: 'Invalid file format',
  COMPANY: {
    UPDATE: 'Company updated successfully',
    CREATE: 'Company created successfully',
    MEMBERSHIP_UPDATE: 'Membership updated successfully',
    MEMBERSHIP_CREATE: 'Membership created successfully',
    RESIGN: 'User resigned successfully',
    CREATE_FIRST: 'Create a company first',
    CREATE_MEMBERSHIP_FIRST: 'Create a membership type first',
  },
  EVENT: {
    CREATE_EVENT_FIRST: 'Create an event first',
    CREATE_EVENT: 'Event created Successfully',
    UPDATE_EVENT: 'Event updated Successfully',
    EVENT_CANCEL: 'Event canceled successfully',
    EVENT_DELETE: 'Event deleted successfully',
    EVENT_REMOVED: 'Event removed successfully',
  },
  PRICINGANDBOOKING: {
    UPDATE: 'Pricing and booking updated successfully',
    CREATE: 'Pricing and booking created successfully',
  },
  FURTHER_DETAIL: {
    LEFT: 'Left Hand Side',
    RIGHT: 'Right Hand Side',
    SINGLE: 'Single Detail',
    UPDATE: 'Event descriptions updated successfully',
  },
  IMAGE: {
    CHANGE_IMAGE: 'Change Image',
    CHANGE_PDF: 'Change File',
  },
  NOTES: {
    NOTES_CREATION: 'Note created successfully',
  },
  INDIVIDUAL_USER: {
    UPDATE: 'User updated successfully',
    CREATE: 'User created successfully',
    MEMBERSHIP_UPDATE: 'Membership updated successfully',
    MEMBERSHIP_CREATE: 'Membership created successfully',
    CREATE_USER_FIRST: 'Create an user first',
    PAYMENT_RECORD_ADD: 'Payment record added successfully',
    MEMBERSHIP_PAID_UP_TO: 'Membership paid up to date updated successfully',
  },
  ATTENDEE: {
    INVALID_USER: 'User not found',
    USER_EXISTS: 'User have been booked already',
    ADD: 'Attendee added successfully',
  },
  RESOURCE: {
    PUBLISH: 'Resource published successfully',
    DRAFT: 'Resource drafted successfully',
    DELETE: 'Resource deleted successfully',
    UPDATE: 'Resource updated successfully',
  },
  CPD: {
    CREATED: 'CPD Created successfully',
    UPDATED: 'CPD Updated successfully',
  },
  MEMBERSHIP: {
    UPDATE: 'Membership updated successfully',
    CREATE: 'Membership created successfully',
    DELETE: 'Membership deleted successfully',
  },
  ADMIN: {
    CREATE: 'Create an admin first',
  },
  EVENT_BOOKING: {
    UPDATE: 'Member status updated successfully',
  },
  PAGES: {
    COMPLIANCE: 'You must select 3 featured compliance events',
    EVENTS: 'You must select 3 featured events',
    RESOURCE: 'You must select 3 featured resources',
    RESOURCE_MIN: 'You must select at least 3 featured resources',
  },
  SPEAKER: {
    UPDATE: 'Speaker updated successfully',
    CREATE: 'Speaker created successfully',
  },
  USER: {
    DELETE_TITLE: 'Delete User Permanently',
    RESPONSE_MESSAGE: 'Permanent Deletion Of User Completed Successfully',
    DELETE_MESSAGE:
      'Are you certain that you wish to proceed with the permanent deletion of user ? Please be aware that once this action is completed, all associated information will be irreversibly removed from our system.',
  },
};
export default lang;
